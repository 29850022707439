<template>
  <div
    class="form-create d-flex justify-content-center align-items-center flex-column beforeFormLogin"
    v-if="ListSettingShop"
  >
    <div class="logo img-logo-header">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="row mb-3 px-5">
      <div class="col-lg-6 col-sm-12 spborder" style="margin-top: 50px">
        <div class="sp-p0">
          <form autocomplete="off">
            <div class="form-title">アカウント作成後購入画面に移ります</div>
            <p class="sp-only login-title">メールアドレスでアカウントを作成</p>
            <div class="d-flex flex-column form-input">
              <label for="email" style="font-size: 16px"
                ><span style="color: black"
                  >ユーザー名またはメールアドレス</span
                >
                <span style="color: red"> (必須)</span></label
              >
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="受信可能なメールアドレスを入力してください"
                autocomplete="new-password"
                style="color: black"
              />
            </div>
            <!-- <div class="d-flex flex-column form-input">
              <label for="email" style="font-size: 16px"
                ><span class="col-lg-12 px-0" style="color: black"
                  >ご希望の英数字を含む半角8文字以上のパスワード&emsp;&emsp;</span
                >
                <span style="color: red"> (必須)</span></label
              >
              <input
                v-model="password"
                type="password"
                name="password"
                id="password"
                placeholder="8文字以上の英数字でパスワードを入力してください"
                autocomplete="new-password"
                v-bind:class="{ 'error-input': errorField.password_request }"
              />
              <div
                class="message-error"
                style="margin-bottom: 20px"
                v-if="errorField.password_request"
              >
                {{ messageError.password_request }}
              </div>
            </div> -->
            <!-- <div class="d-flex flex-column form-input">
              <input
                v-model="confirmPasword"
                type="password"
                name="confirmPasword"
                id="confirmPassword"
                placeholder="パスワードを再入力してください。"
                autocomplete="new-password"
              />
            </div> -->
            <div class="btn-send">
              <b-button
                style="
                  border-color: #333;
                  background: #333;
                  color: white;
                  width: 50%;
                "
                v-on:click.prevent="createNewMember()"
                :disabled="isLoading"
              >
                <span>アカウントを作成</span>
                <b-spinner v-if="isLoading" small></b-spinner>
              </b-button>
            </div>
            <div class="text-center mb-5" style="font-size: 16px">
              <span
                >ログインすることにより、当社の
                <a
                  class="text-decoration-underline"
                  style="color: black"
                  v-b-modal.modal-terms
                  >利用規約</a
                >
                および
                <a
                  class="text-decoration-underline"
                  style="color: black"
                  v-b-modal.modal-policy
                  >プライバシー規約</a
                >約に同意したとみなされます。</span
              >
            </div>
            <!--<div class="container login-sns">
              <div class="row">
                <div
                  class="col-lg-12 mx-auto"
                  style=""
                  v-if="isCheckFacebook === 1"
                >
                  <a
                    :href="`${urlBackend}/auth/facebook?shop_id=${shop_id}&slag_token=${slagToken}`"
                  >
                    <button class="btn-common btn-facebook">
                      <i class="fab fa-facebook-square size-icon"></i>
                      Facebook でログインする
                    </button>
                  </a>
                </div>
                <div class="col-lg-12" v-if="isCheckTwitter === 1">
                  <a>
                    <button
                      class="btn-common btn-twitter"
                      v-on:click.prevent="loginTwitter()"
                    >
                      <i class="fab fa-twitter size-icon"></i>
                      Twitter でログインする
                    </button>
                  </a>
                </div>
                <div class="col-lg-12" v-if="isCheckLine === 1">
                  <button
                    class="btn-common btn-line"
                    v-on:click.prevent="loginLine()"
                  >
                    <i class="fab fa-line size-icon"></i>
                    <i class="bi bi-activity"></i>
                    Line でログインする
                  </button>
                </div>
                <div class="col" v-if="isCheckAmazon === 1">
                  <a
                    :href="`${urlBackend}/auth/amazon?shop_id=${shop_id}&slag_token=${slagToken}`"
                  >
                    <button
                      class="btn-common btn-amazon"
                      v-on:click.prevent="loginAmazon()"
                    >
                      <i class="fab fa-amazon size-icon"></i>
                      Amazon でログインする
                    </button>
                  </a>
                </div>
              </div>
            </div>-->
          </form>
          <span class="title2">すでにアカウントをお持ちですか?</span>
          <div class="btn-send" style="margin-bottom: 80px">
            <b-button
              v-on:click="ScreenLogin()"
              style="
                background: #f1f2f3;
                color: #5e5e64;
                border: 0.5px solid black;
              "
              >ログイン画面へ</b-button
            >
          </div>
        </div>
      </div>
      <div
        class="col-lg-6 col-sm-12 px-3"
        style="padding-top: 6%"
        v-if="InfoContent"
      >
        <div class="pt-4 pb-4" style="background: #424b55">
          <h6 class="text-center customTitle">お申込内容</h6>
        </div>
        <div style="background: white" class="p-3">
          <div class="mt-2 mb-2">
            <CRow>
              <CCol sm="12" class="customTitleContent">
                {{ InfoContent.title || InfoContent.content_title }}
              </CCol>
              <CCol
                sm="12"
                class="customAmountContent"
                v-if="infoSubription.isSubscription"
              >
                {{
                  Math.round(Number(InfoContent.totalPrice)).toLocaleString(
                    "ja"
                  )
                }}円 /{{ infoSubription.addBill
                }}{{ infoSubription.textChange }}（税抜）
              </CCol>
              <CCol v-else sm="12" class="customAmountContent">
                {{
                  Math.round(Number(InfoContent.totalPrice)).toLocaleString(
                    "ja"
                  )
                }}
                円（税抜）
              </CCol>
            </CRow>
          </div>
          <div class="mt-5 mb-5">
            <CRow>
              <CCol sm="12"
                ><span style="font-size: 18px">送料・手数料</span
                ><span
                  style="margin: 0 0 0 1em; font-weight: bold; font-size: 18px"
                  >&nbsp;&nbsp;&nbsp; {{ InfoContent.fee }}円（税抜）</span
                >
              </CCol>
              <CCol sm="12"
                ><span style="font-size: 18px"> 消費税</span
                ><span
                  style="margin: 0 0 0 4em; font-weight: bold; font-size: 18px"
                  >&nbsp;&nbsp;&nbsp;
                  {{
                    Math.round(Number(InfoContent.tax)).toLocaleString("ja")
                  }}円</span
                >
              </CCol>
              <CCol
                sm="12"
                v-if="
                  infoSubription.isSubscription &&
                  infoSubription.trialPeriod > 0
                "
                ><span style="font-size: 18px"> お試し期間</span
                ><span
                  style="margin: 0 0 0 2em; font-weight: bold; font-size: 18px"
                  >&nbsp;&nbsp;&nbsp; {{ infoSubription.trialPeriod }}日間</span
                >
              </CCol>
              <CCol
                sm="12"
                v-if="
                  infoSubription.isSubscription &&
                  infoSubription.billingCount > 0
                "
                ><span style="font-size: 18px"> 請求回数</span
                ><span
                  style="margin: 0 0 0 3em; font-weight: bold; font-size: 18px"
                  >&nbsp;&nbsp;&nbsp; {{ infoSubription.billingCount }}回</span
                >
              </CCol>
            </CRow>
          </div>
          <!-- <div class="mb-5">
            <CRow>
              <CCol sm="12">注文内容: {{ totalOrder }}点 </CCol>
              <CCol sm="12"
                >商品合計：￥{{ Number(totalPrice).toLocaleString("ja") }}円
              </CCol>
              <CCol sm="12" v-if="totalPointUse > 0"
                >ポイント利用：-{{
                  Number(totalPointUse).toLocaleString("ja")
                }}円
              </CCol>
              <CCol sm="12" v-else>ポイント利用：0円 </CCol>
            </CRow>
          </div> -->
          <!-- <div class="mb-5">
            <CRow>
              <CCol sm="12" style="color: red; font-weight: bold"
                >ご請求額：{{
                  Number(totalPricePayment).toLocaleString("ja")
                }}円(税込)
              </CCol>
              <CCol sm="12">加算ポイント：{{ totalPointBonus }} </CCol>
            </CRow>
          </div> -->
        </div>
        <div style="background: #e6e8ea" class="p-3">
          <div class="" style="background: #e6e8ea">
            <CCol sm="12" class="px-0 d-flex justify-content-between"
              ><span style="font-size: 18px; font-weight: bold">
                合&nbsp;&nbsp; 計</span
              ><span v-if="infoSubription.isSubscription" class="totalAmount"
                >&nbsp;&nbsp;&nbsp;
                {{
                  Math.round(
                    Number(InfoContent.totalPricePayment)
                  ).toLocaleString("ja")
                }}円 /{{ infoSubription.addBill
                }}{{ infoSubription.textChange }}（税抜）</span
              >
              <span v-else class="totalAmount"
                >&nbsp;&nbsp;&nbsp;
                {{
                  Math.round(
                    Number(InfoContent.totalPricePayment)
                  ).toLocaleString("ja")
                }}円</span
              >
            </CCol>
            <CCol
              sm="12"
              class="px-0 d-flex justify-content-between"
              v-if="infoSubription.isSubscription && infoSubription.initialCost"
            >
              <span style="font-size: 18px; font-weight: bold">初期費用</span>
              <span class="totalAmount"
                >&nbsp;&nbsp;&nbsp;
                {{
                  Math.round(
                    Number(infoSubription.initialCost * 1)
                  ).toLocaleString("ja")
                }}円（税込）
              </span>
            </CCol>
          </div>
        </div>
        <div class="col-sm-12 px-0">
          <div
            class=""
            style="
              background: white;
              padding: 15px 10px;
              font-size: 15px;
              color: #666;
            "
          >
            <span style=""
              >分割決済の場合、分割手数料が別途かかります。
              分割手数料はカード会社によって異なります。</span
            >
          </div>
        </div>
        <div class="w-100">
          <b-modal id="modal-terms" hide-header hide-footer class="w-100">
            <div class="d-block text-center"></div>
            <div class="d-flex justify-content-center">
              <div class="w-100 customImg" v-html="htmlTerms" />
            </div>
          </b-modal>
        </div>
        <div class="w-100">
          <b-modal id="modal-policy" hide-header hide-footer class="w-100">
            <div class="d-block text-center"></div>
            <div class="justify-content-center">
              <div class="w-100 customImg" v-html="htmlPolicy" />
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <b-modal id="active-modal-page" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          すでに同システム利用の他サイトにて、登録済みです。
          ログイン情報を共通化させますか？
          （登録不要で、すぐログインが可能です。
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="confirmAccount()"
          >受け入れる</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="closeModal()"
        >
          キャンセル
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Api } from "../../utils/http-common";
import { Constants } from "../../utils/constants";
import axios from "axios";
// import { FeedBack } from "@/utils/feedback.js";
import no_image from "@/assets/img/logo_default.png";

export default {
  name: "BeforeCreateMember",
  data() {
    return {
      urlBackend: Constants.URL_BE_API,
      email: "",
      password: "",
      confirmPasword: "",
      firstName: "",
      lastName: "",
      isLoading: false,
      alertMessage: false,
      nick_name: "",
      postal_code: "",
      address: "",
      birthday: "",
      sex: 1,
      tel: "",
      receive_promotional_emails: true,
      shopId: null,
      errorField: {
        password_request: null,
      },
      messageError: {
        password_request: "",
      },
      logoShop: null,
      shop_id: this.$route.params.shopId,
      UrlRedirect: null,
      InfoContent: null,
      totalPointBonus: null,
      totalPrice: null,
      fee: null,
      totalPricePayment: null,
      totalOrder: null,
      tax: null,
      PriceContent: null,
      TotalPriceContent: null,
      htmlTerms: null,
      htmlPolicy: null,
      isCheckFacebook: null,
      isCheckLine: null,
      isCheckTwitter: null,
      isCheckAmazon: null,
      slagToken: this.$route.query.token,
      infoSubription: {
        isSubscription: false,
        textChange: "",
        initialCost: "",
        trialPeriod: "",
        billInternal: "",
        addBill: "",
        billingCount: "",
      },
      paymentType: Constants.PAYMENT_TYPE,
      dataHTML: null,
      dataActiveToken: null,
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
    };
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "ListSettingShop",
      "InfoContentPayment",
      "dataGeneral",
      "detailcontentPreSales",
    ]),
  },
  async created() {
    await this.$store.dispatch("getShopById", {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    });
    this.shop_id = localStorage.getItem(Constants.SHOP_ID);
    this.shopId = this.shop_id;
    // const content_id = this.$route.params.idContent;
    // this.getInforContentPayment(content_id);
    const { idContent } = this.$route.params;
    let content_id;
    if (
      this.$route.name.includes("payment") ||
      this.$route.name.includes("confirm_payment")
    ) {
      content_id = this.$route.params.id;
    } else {
      content_id = idContent;
    }
    this.getDetailContentPreSales({ shopId: this.shopId, id: content_id });
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
    this.getGeneralByIdContent(this.shop_id);
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = true;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
    // InfoContentPayment() {
    //   this.InfoContent = this.InfoContentPayment;
    //   this.fee = this.InfoContentPayment.shipping;
    //   this.PriceContent = Math.floor(
    //     this.InfoContentPayment.amount /
    //       (1 + this.InfoContentPayment.tax_rate * 0.01)
    //   );
    //   this.TotalPriceContent = Math.floor(
    //     this.InfoContentPayment.amount + this.fee
    //   );
    //   this.tax = Math.floor(this.InfoContentPayment.amount - this.PriceContent);
    //   this.htmlTerms = this.InfoContentPayment.author.terms;
    //   this.htmlPolicy = this.InfoContentPayment.author.policy;
    // },
    detailcontentPreSales() {
      if (Object.keys(this.detailcontentPreSales).length > 0) {
        if (
          !this.detailcontentPreSales[0].sale_basic ||
          !this.detailcontentPreSales[0].sale_basic.column
        ) {
          const shopId = this.$route.params.shopId
            ? this.$route.params.shopId
            : localStorage.getItem(Constants.SHOP_ID);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        }
        this.InfoContent = this.detailcontentPreSales[0];
        if (
          this.InfoContent.sale_basic.column[0].product_price === null ||
          !this.InfoContent.sale_basic.column[0].product_price
        ) {
          const shopId = this.$route.params.shopId
            ? this.$route.params.shopId
            : localStorage.getItem(Constants.SHOP_ID);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        }
        const tax = this.InfoContent.sale_basic.column[0].tax
          ? this.InfoContent.sale_basic.column[0].tax
          : this.InfoContent.tax_default;
        this.InfoContent.product_price =
          this.InfoContent.sale_basic.column[0].product_price;
        this.InfoContent.totalPrice =
          this.InfoContent.product_price / (1 + tax * 0.01);
        this.InfoContent.tax =
          this.InfoContent.product_price - this.InfoContent.totalPrice;
        this.InfoContent.fee = this.InfoContent.sale_basic.column[0].fee
          ? this.InfoContent.sale_basic.column[0].fee
          : this.InfoContent.consultation_fee_default;
        if (
          this.InfoContent.sale_basic.column[0].sale_format ===
          this.paymentType.subscription
        ) {
          const bill_internal =
            this.InfoContent.sale_basic.column[0].billInternal;
          this.infoSubription.isSubscription = true;
          this.infoSubription.initialCost =
            this.InfoContent.sale_basic.column[0].initialCost;
          this.infoSubription.trialPeriod = this.InfoContent.sale_basic
            .column[0].trialPeriod
            ? this.InfoContent.sale_basic.column[0].trialPeriod
            : 0;
          this.infoSubription.addBill =
            this.InfoContent.sale_basic.column[0].addBill;
          this.infoSubription.billingCount =
            this.InfoContent.sale_basic.column[0].billingCount;
          this.infoSubription.textChange =
            bill_internal === 1
              ? "日"
              : bill_internal === 2
              ? "週"
              : bill_internal === 3
              ? "ヶ月"
              : "年";
        }
        this.InfoContent.totalPricePayment =
          this.InfoContent.product_price * 1 + this.InfoContent.fee * 1;
        this.dataHTML = this.InfoContent.sale_other.newsletters;
      }
    },
    dataGeneral() {
      if (this.dataGeneral) {
        this.isCheckFacebook = this.dataGeneral.facebook_status;
        this.isCheckLine = this.dataGeneral.line_status;
        this.isCheckTwitter = this.dataGeneral.twitter_status;
        this.isCheckAmazon = this.dataGeneral.amazone_status;
      }
    },
    // URLByToken() {
    //   this.UrlRedirect = this.URLByToken.redirect_url;
    // },
  },
  methods: {
    ...mapActions({
      createMember: "createMember",
      getListSettingShopUser: "getListSettingShopUser",
      getInforContentPayment: "getInforContentPayment",
      createQuickUser: "createQuickUser",
      getGeneralByIdContent: "getGeneralByIdContent",
      getDetailContentPreSales: "getDetailContentPreSales",
      activeAccount: "activeAccount",
      getShopById: "getShopById",
    }),
    setAlert() {
      this.alertMessage = true;
    },
    async createNewMember() {
      this.isLoading = true;
      const ShopID = localStorage.getItem(Constants.SHOP_ID);
      if (this.email == "") {
        this.$toasted.error("メールフィールドは必須です。");
        this.isLoading = false;
      } else if (!this.email.match(Constants.REGEX_EMAIL)) {
        this.$toasted.error("メールフィールドが無効です。");
        this.isLoading = false;
      } else {
        // const { params } = this.$route;
        const formCreate = {
          shop_id: ShopID,
          email: this.email,
          content_id: this.$route.params.idContent,
        };
        const dataReturn = await this.$store.dispatch(
          "createQuickUser",
          formCreate
        );
        if (dataReturn.success === true) {
          if (dataReturn.data.active_token) {
            this.dataActiveToken = dataReturn.data.active_token;
            this.$bvModal.show("active-modal-page");
          } else {
            const { access_token, user, expires_in } = dataReturn.data;
            const { user_type } = user;
            const expires_at = new Date(
              new Date().setSeconds(new Date().getSeconds() + expires_in)
            );
            localStorage.setItem(Constants.TOKEN_USER, access_token);
            localStorage.setItem(Constants.USER_TYPE_USER, user_type);
            localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
            localStorage.setItem(Constants.USER_ID, user.user_id);
            localStorage.setItem(Constants.EMAIL_USER, user.email);
            localStorage.setItem(
              Constants.NORMAL_USER_INFO,
              JSON.stringify(user)
            );
            localStorage.setItem(Constants.IS_REMEMBER_USER, false);
            if (parseInt(this.InfoContent.totalPricePayment) === 0) {
              this.$router.push({
                name: this.$route.params.shopId
                  ? "content detail"
                  : "content detail domain",
                params: {
                  shopId: ShopID,
                  id: this.$route.params.idContent,
                },
              });
            } else {
              if (this.is_login == false) {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "paymentBuy"
                    : "paymentBuy domain",
                  params: {
                    shopId: ShopID,
                    id: this.$route.params.idContent,
                  },
                  query: {
                    urlSale: true,
                  },
                });
              } else {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "paymentBuy"
                    : "paymentBuy domain",
                  params: {
                    shopId: ShopID,
                    id: this.$route.params.idContent,
                  },
                });
              }
              this.isLoading = false;
            }
            var dataObject = new FormData();
            for (let i = 0; i < this.dataHTML.length; i++) {
              let formHTML = new DOMParser()
                .parseFromString(this.dataHTML[i].form_html, "text/html")
                .getElementsByTagName("*");
              for (let j = 0; j < formHTML.length; j++) {
                if (
                  formHTML[j].getAttribute("name") !== null &&
                  formHTML[j].getAttribute("name") === this.dataHTML[i].name
                ) {
                  dataObject.append(
                    formHTML[j].getAttribute("name"),
                    this.email
                  );
                } else if (
                  formHTML[j].getAttribute("name") !== null &&
                  formHTML[j].getAttribute("name") === this.dataHTML[i].email
                ) {
                  dataObject.append(
                    formHTML[j].getAttribute("name"),
                    this.email
                  );
                } else if (
                  formHTML[j].getAttribute("name") !== null &&
                  formHTML[j].getAttribute("name") !== this.dataHTML[i].name
                ) {
                  dataObject.append(
                    formHTML[j].getAttribute("name"),
                    formHTML[j].getAttribute("value")
                  );
                }
              }
              if (this.dataHTML[i].method.toLowerCase() === "get") {
                axios
                  .get(this.dataHTML[i].action, {
                    params: dataObject,
                  })
                  .then(() => {});
                axios.get(this.dataHTML[i].action, dataObject).then(() => {});
              } else {
                axios.post(this.dataHTML[i].action, dataObject).then(() => {});
              }
            }
          }
        } else {
          this.isLoading = false;
          this.$toasted.error(dataReturn.message);
        }
        // this.createMember(formCreate);
      }
    },
    loginFacebook() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/facebook?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginTwitter() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/twitter?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginLine() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/line?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginAmazon() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/amazon?shop_id=${dataRequest.shop_id}&slag_token=${dataRequest.slag_token}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ScreenLogin() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Before Login"
          : "Before Login domain",
        params: { shop_id: this.$route.params.shopId },
      });
    },
    closeModal() {
      this.isLoading = false;
      this.$bvModal.hide("active-modal-page");
    },
    async confirmAccount() {
      const dataRequest = {
        token: this.dataActiveToken,
      };
      const dataReturn = await this.activeAccount(dataRequest);
      console.log("dataReturn", dataReturn);
      this.$bvModal.hide("active-modal-page");
      if (this.$route.query.token !== null) {
        if (parseInt(this.InfoContent.totalPricePayment) === 0) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "content detail"
              : "content detail domain",
            params: {
              shopId: this.shopId,
              id: this.$route.params.idContent,
            },
          });
        } else {
          this.$router.push({
            name: this.$route.params.shopId ? "payment" : "payment domain",
            params: {
              shopId: this.$route.params.shopId,
              id: this.$route.params.idContent,
            },
          });
        }
        const { access_token, user, expires_in } = dataReturn.data;
        const { user_type } = user;
        const expires_at = new Date(
          new Date().setSeconds(new Date().getSeconds() + expires_in)
        );
        localStorage.setItem(Constants.TOKEN_USER, access_token);
        localStorage.setItem(Constants.USER_TYPE_USER, user_type);
        localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
        localStorage.setItem(Constants.USER_ID, user.user_id);
        localStorage.setItem(Constants.EMAIL_USER, user.email);
        localStorage.setItem(Constants.NORMAL_USER_INFO, JSON.stringify(user));
        localStorage.setItem(Constants.IS_REMEMBER_USER, false);
        var dataObject = new FormData();
        for (let i = 0; i < this.dataHTML.length; i++) {
          let formHTML = new DOMParser()
            .parseFromString(this.dataHTML[i].form_html, "text/html")
            .getElementsByTagName("*");
          for (let j = 0; j < formHTML.length; j++) {
            if (
              formHTML[j].getAttribute("name") !== null &&
              formHTML[j].getAttribute("name") === this.dataHTML[i].name
            ) {
              dataObject.append(formHTML[j].getAttribute("name"), this.email);
            } else if (
              formHTML[j].getAttribute("name") !== null &&
              formHTML[j].getAttribute("name") === this.dataHTML[i].email
            ) {
              dataObject.append(formHTML[j].getAttribute("name"), this.email);
            } else if (
              formHTML[j].getAttribute("name") !== null &&
              formHTML[j].getAttribute("name") !== this.dataHTML[i].name
            ) {
              dataObject.append(
                formHTML[j].getAttribute("name"),
                formHTML[j].getAttribute("value")
              );
            }
          }
          if (this.dataHTML[i].method.toLowerCase() === "get") {
            axios
              .get(this.dataHTML[i].action, {
                params: dataObject,
              })
              .then(() => {});
            axios.get(this.dataHTML[i].action, dataObject).then(() => {});
          } else {
            axios.post(this.dataHTML[i].action, dataObject).then(() => {});
          }
        }
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: {
            shopId: this.shopId,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.beforeFormLogin {
  width: 1020px !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 420px) {
    width: 100% !important;
  }
}
.img-logo-header {
  width: 286px;
  height: 79px;
}
.error-input {
  border: solid 1px #e55353 !important;
  margin-bottom: 0px !important;
}
.message-error {
  color: #e55353;
}
.customTitleContent {
  color: #3c434a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: bold;
  font-size: 18px;
}
.customAmountContent {
  color: #900;
  font-weight: bold;
  font-size: 23px;
  text-align: right;
  padding: 7px 0;
}
.totalAmount {
  // float: right;
  text-align: right;
  color: #d45452;
  font-size: 23px;
  font-weight: bold;
}
.title2 {
  color: #72777c;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  display: flex;
  align-items: center;
}
.title2::before,
.title2::after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title2:before {
  margin-right: 0.5rem;
}
.title2:after {
  margin-left: 0.5rem;
}
.auth-layout {
  .auth-form {
    @media (max-width: 420px) {
      width: 100% !important;
    }
  }
}
.customImg {
  img {
    width: 100%;
  }
}
</style>
